import React, { useRef, useMemo, useState } from 'react'
import classnames from 'classnames'

import Button, { ButtonGroup } from 'components/Base/Button'
import SwiperCore, { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
// import 'swiper/components/pagination/pagination.less'
import ScanCodeConsultButton from 'components/ScanCodeConsultButton'

import * as styles from './index.module.less'

SwiperCore.use([Pagination])

interface BannerProps {
  className?: string
  bannerIcon?: string
  bannerData: any
  active: number
  handleChangeIndex: (index: number) => void
  curVideo: any
}

const Banner: React.FC<BannerProps> = ({ className, bannerData, bannerIcon, active, handleChangeIndex, curVideo }) => {
  //   const { banner_icon, banner_video_img } = bannerData

  const mySwiper = useRef<SwiperCore>()
  const videoArray = bannerData.map((item) => item.banner_video)
  // const [activeIndex, setActiveIndex] = useState<number>(active)
  const videoUrl = useMemo(() => {
    mySwiper.current?.slideTo(active)
    return videoArray[active]
  }, [active])

  return (
    <div className={classnames('lg:block lg:relative hidden', styles.BannerContainer, className)}>
      <div className="lg:h-[424px]">
        <img className="w-full h-full" src={bannerIcon} alt="" />
      </div>
      <div className="lg:h-[138px] bg-[#fff]"></div>
      <div className="lg:absolute lg:w-[1200px] bottom-0 top-[156px] left-[50%] translate-x-[-50%] ">
        <Swiper
          onSwiper={(swiper) => {
            mySwiper.current = swiper
          }}
          onSlideChange={(swiper) => {
            handleChangeIndex(swiper.activeIndex)
          }}
          autoplay={false}
          spaceBetween={10}
          pagination={{ clickable: true }}
          className="h-full"
        >
          {bannerData.map((item) => {
            return (
              <SwiperSlide key={item?.title}>
                <div>
                  <div className="banner-video h-[366px] bg-[rgba(255,255,255,0.9)] px-[18px] py-[18px] flex">
                    <div
                      className="relative rounded-[4px]"
                      onClick={() => {
                        if (curVideo.current) {
                          curVideo.current.changeSource({ src: videoUrl })
                        }
                        setTimeout(() => {
                          curVideo.current.playVideo()
                        }, 200)
                        // const myVideo: HTMLVideoElement | any = document.getElementById('vjs_video_3_html5_api')
                        // if (myVideo) {
                        //   // 动态调整video资源地址
                        //   myVideo.src = videoArray[active]
                        // }
                        // curVideo.current.playVideo()
                        // myVideo.play()
                      }}
                    >
                      <span className="banner_video_mask absolute top-0 left-0 bottom-0 right-0 bg-[rgba(0,0,0,0.3)] cursor-pointer rounded-[4px]">
                        <img
                          className="absolute w-[44px] h-[44px] left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] "
                          src="https://ow-file.sensorsdata.cn/www/solutions/overview/play_video_icon.png"
                          alt=""
                        />
                      </span>
                      <img
                        className="solution-overview-lazy-img h-full w-[584px] rounded-[4px]"
                        data-src={item?.banner_video_img?.publicURL || item?.banner_video_img}
                        alt={item?.title}
                      />
                    </div>
                    <div className="ml-[40px] pr-[26px] flex-1 flex flex-col justify-center">
                      <div>
                        <span className="text-[18px] font-medium leading-[24px] text-[#1F2D3D]">{item?.title}</span>{' '}
                        <span className="inline-block ml-[18px] py-[2px] px-[8px] text-[12px] text-[#04CB94] bg-[rgba(4,203,148,0.1)] leading-[18px] rounded-[4px]">
                          行业版介绍视频
                        </span>
                      </div>
                      <p className="mt-[10px] text-[14px] leading-[20px] text-[#475669]">{item?.desc}</p>
                      <p className="mt-[62px]">
                        <ButtonGroup>
                          <Button btnType="primary" href={item?.btn_href} target="_blank">
                            {item?.btn_text}
                          </Button>
                          <ScanCodeConsultButton
                            className="ml-[20px] bg-[#fff] hover:!bg-[#E6FAF5]"
                            buttonText="立即咨询"
                            imgBottomText="微信扫码 立即咨询"
                            size="small"
                            buttonType="primary"
                            ghost
                            qrcode="https://ow-file.sensorsdata.cn/www/solutions/overview/wxcode.png"
                          />
                        </ButtonGroup>
                      </p>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            )
          })}
        </Swiper>
      </div>
    </div>
  )
}

export default Banner
