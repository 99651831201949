import React, { useRef, useMemo } from 'react'
import { useScroll } from 'ahooks'
import { isPc } from 'utils/utils'

import classnames from 'classnames'
import * as styles from './index.module.less'

interface TagProps {
  className?: string
  tagData: any[]
  active: number
  handleChangeIndex: (index: number) => void
}

const PcClassName = 'lg:leading-[72px] lg:px-[20px] lg:text-[18px] lg:text-[#181818] lg:cursor-pointer lg:py-0 '
const MbClassName = 'px-[16px] text-[14px]  leading-[44px] whitespace-nowrap  w-auto '
const tagClassName = PcClassName + MbClassName

const Tag: React.FC<TagProps> = ({ className, tagData, active, handleChangeIndex }) => {
  const tagElement = useRef(null)
  const scroll = useScroll(globalThis?.document)
  const fixedFlag = useMemo(() => {
    if (scroll && scroll?.top >= 770) {
      return true
    } else {
      return false
    }
  }, [scroll])
  return (
    <div className={classnames(styles.container, 'h-auto lg:h-[72px]', className)}>
      <div
        className={classnames(
          styles.tag,
          'fixed z-[97] lg:relative lg:z-[665] lg:top-0 !flex top-[4rem] left-0 w-full lg:block h-[44px] lg:h-[72px]   lg:justify-center flex-nowrap overflow-x-scroll px-[20px] lg:px-auto',
          { 'fix-tag': fixedFlag && isPc() },
        )}
        ref={tagElement}
      >
        {tagData?.map((el, index) => {
          return (
            <div
              className={classnames('tag-item ', tagClassName, {
                activeIndex: active === index,
              })}
              key={index + 'tag'}
              onClick={() => {
                handleChangeIndex(index)
              }}
            >
              {el?.name}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Tag
