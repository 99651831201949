/* eslint-disable */
import * as React from 'react'
import * as styles from './index.module.less'

const PrivacyPolicy = (props) => {
  const { isShow = false, onClose } = props
  return (
    <div className={styles.PrivacyPolicy} style={{ display: isShow ? 'flex' : 'none' }}>
      <section className="content">
        <p className="close" onClick={onClose}>
          <img src={require('../../assets/images/icons/icon-close.svg').default} alt="" />
        </p>
        <iframe title="readme" src="https://www.sensorsdata.cn/compliance/privacy.html" frameBorder="0"></iframe>
      </section>
    </div>
  )
}

export default PrivacyPolicy
