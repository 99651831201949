import React, { useEffect, useRef, useState } from 'react'
import classnames from 'classnames'
import { string } from 'prop-types'
import { useRequest } from 'ahooks'

import * as styles from './index.module.less'
import useOutsideClick from 'hooks/useOutsideClick'
import { navigate } from 'gatsby'

interface SearchInputProps {
  className?: string
  defaultPlaceholder?: string
  onSearch?: (searchKey: string, setSearchResults: React.Dispatch, setShowResult: React.Dispatch) => void
}

export interface SearchResultProp {
  name: string
  cate: string
  key: string
}
const SearchInput: React.FC<SearchInputProps> = (props) => {
  const { className, defaultPlaceholder, onSearch } = props

  const inlineStyles = {
    SearchInputWrap: `flex flex-row h-[30px] lg:h-[60px] w-full lg:w-[800px] mx-auto`,
    searchWrap: `relative flex-grow`,
    SearchInput: `search-input w-full h-full rounded-none    border-primary px-[10px] lg:px-[20px] placeholder:text-sd-font-input text-sd-black text-[12px] lg:text-[16px] leading-normal`,
    SearchInputActive: `border-[1px] border-t-0  border-primary rounded-bl-[0px]`,
    searchButton: `sd-button-primary search-btn flex flex-row items-center justify-center w-[70px] lg:w-[130px]  lg:hover:bg-primary-dark cursor-pointer`,
    searchIcon: ` w-[24px] h-[24px]`,
    searchText: `text-primary text-[16px] ml-[5px] hidden lg:block text-sd-light`,
    searchListWrap: `absolute w-full left-0 top-[40px] z-[1] bg-sd-light border-[1px] border-t-0 border-primary rounded-bl-[4px] rounded-br-[4px] overflow-hidden`,
    searchList: `max-h-[200px] overflow-scroll`,
    searchListItem: `h-[38px] px-[10px] lg:px-[20px] flex flex-row justify-between group hover:bg-sd-list-hover focus:outline-0`,
    noDataPlaceholderWrap: `h-[38px] px-[10px] lg:px-[20px] flex flex-row justify-between`,
    noDataPlaceholder: `text-sd-font-input text-[12px] lg:text-[16px] leading-[38px] align-middle`,
    searchListItemText: `text-sd-black text-[12px] lg:text-[16px] leading-[38px] align-middle group-hover:text-primary line-clamp-1`,
    gapLine: `h-[1px] mx-[10px] lg:mx-[20px] mt-[10px] bg-sd-gap-line`,
  }

  const wrapRef = useRef<HTMLDivElement | null>(null)

  const [searchKey, setSearchKey] = useState<string>('')
  const [searchResults, setSearchResults] = useState<Array<SearchResultProp>>([])
  const [showResult, setShowResult] = useOutsideClick(searchResults.length > 0, wrapRef)
  const [composing, setComposing] = useState<boolean>(false)

  const onChange = function (searchKey: string): void {
    onSearch && onSearch(searchKey, setSearchResults, setShowResult)
  }

  const { run: handleChange } = useRequest(onChange, {
    debounceWait: 200,
    manual: true,
  })

  const handleDefaultSearch = () => {
    if (searchResults.length > 0) {
      navigate(`/target_disassembly/target.html?id=${encodeURIComponent(searchResults[0].key)}`)
      setShowResult(false)
    }
  }

  const noDataPlaceholder = (
    <li className="text-sd-font-input ">
      <a className={inlineStyles.noDataPlaceholderWrap}>
        <span className={classnames(inlineStyles.noDataPlaceholder)}>暂无数据</span>
      </a>
    </li>
  )

  return (
    <div ref={wrapRef} className={classnames(inlineStyles.SearchInputWrap, styles.SearchInput, className)}>
      <div className={inlineStyles.searchWrap}>
        <input
          name="search"
          maxLength="255"
          className={classnames(
            inlineStyles.SearchInput,
            styles.inlineSearchInput,
            {
              [inlineStyles.SearchInputActive]: showResult,
            },
            'peer',
          )}
          placeholder={defaultPlaceholder}
          autoComplete="off"
          value={searchKey}
          onChange={(e) => {
            const searchKey = e.target.value.trim()
            setSearchKey(searchKey)
            handleChange(searchKey)
          }}
          onCompositionStart={() => {
            setComposing(true)
          }}
          onCompositionEnd={() => {
            setComposing(false)
          }}
          onKeyDown={(e) => {
            if (e.key == 'Enter' && !composing) {
              handleDefaultSearch()
            }
          }}
          onFocus={(e) => {
            if (searchResults.length > 0) {
              setShowResult(true)
            }
          }}
        />
        <div
          className={classnames(inlineStyles.searchListWrap, {
            hidden: !showResult,
            block: showResult,
          })}
        >
          <div className={inlineStyles.gapLine}></div>
          <ul className={inlineStyles.searchList}>
            {searchResults.map((searchResult, index) => (
              <li
                className={`
                    focus:!outline-hidden
                    focus:!outline-0
                  `}
                key={index}
              >
                <a
                  className={inlineStyles.searchListItem}
                  href={`/target_disassembly/target.html?id=${searchResult.key}`}
                >
                  <span className={classnames(inlineStyles.searchListItemText, 'flex-[2]')}>{searchResult.name}</span>
                  <span className={classnames(inlineStyles.searchListItemText, 'flex-1 text-right')}>
                    {searchResult.cate}
                  </span>
                </a>
              </li>
            ))}
            {searchResults.length == 0 && noDataPlaceholder}
          </ul>
        </div>
      </div>
      <div
        onClick={(e) => {
          handleDefaultSearch()
        }}
        className={classnames(inlineStyles.searchButton, 'group')}
      >
        <img
          className={classnames(inlineStyles.searchIcon, 'lightIcon :group-hover:block')}
          src={require('assets/images/target_disassembly/search_icon_light.svg').default}
        />
        <span className={inlineStyles.searchText}>搜索</span>
      </div>
    </div>
  )
}

export default SearchInput
