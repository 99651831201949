import React, { useMemo } from 'react'
import SearchInput, { SearchResultProp } from 'components/SolutionComponents/SearchInput'
import ScanCodeConsultButton from 'components/ScanCodeConsultButton'

import classnames from 'classnames'
import * as styles from './index.module.less'

interface IndexDismantlingProps {
  className?: string
  onSearch?: (string) => Promise<Array<SearchResultProp>>
  tags?: any[]
}

const IndexDismantling: React.FC<IndexDismantlingProps> = ({ className, onSearch, tags = [] }) => {
  const placeholderText = useMemo(() => {
    const strArray = tags?.map((item: any) => item?.tagName)
    const str = '热门搜索指标：' + strArray.join('、')
    return str
  }, [tags])
  return (
    <div className={classnames(styles.container, className)}>
      <div>
        <SearchInput
          className="lg:mt-[60px] "
          defaultPlaceholder={placeholderText}
          onSearch={(key, setSearchResults, setShowResult) => {
            onSearch &&
              onSearch(key)
                .then((data) => {
                  setSearchResults(data)
                  if (key) {
                    setShowResult(true)
                  } else {
                    setShowResult(false)
                  }
                })
                .catch((err) => {
                  console.log(err)
                })
          }}
        />
      </div>
      <div className="mt-[30px] lg:h-[276px] pb-[12px] lg:pb-0 lg:px-[26px] flex">
        <div className="flex-1">
          <img
            className="w-full h-full"
            src="https://ow-file.sensorsdata.cn/www/solutions/overview/dismantling_img.svg"
            alt=""
          />
        </div>
        <div className="flex-1 pl-[40px] hidden lg:block">
          <div className="pt-[46px] text-[14px] text-[#475669] leading-[22px]">
            神策数据基于为 30+ 个细分领域的 2000+
            企业提供数字化服务的经验，打造出一套涵盖互联网、电商、零售、游戏、教育、汽车、房地产、保险等多个行业，近千个指标的体系化指标拆解工具。可辐射业务、运营、数据分析等多个岗位角色，同时由神策数据行业资深分析师搭配场景案例，对重点指标进行深入解读。
          </div>
          <div className="mt-[38px]">
            <ScanCodeConsultButton
              buttonText="咨询专属顾问"
              imgBottomText="微信扫码 即刻咨询"
              qrcode="https://ow-file.sensorsdata.cn/www/solutions/overview/dismantling_icon_new.png"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default IndexDismantling
