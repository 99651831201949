import React, { useContext, useEffect, useState, useMemo } from 'react'
import Button from 'components/Base/Button'
import useLoginDialog from 'hooks/useLoginDialog'
import { providerContext } from 'components/Provider'
import SubscribeForm from '../SubscribeForm/index.js'
import { getUserCertificationNew } from 'api/index'
import { isPc } from 'utils/utils'
import { saTrackSolutionsOverviewLoginClick } from 'utils/statistics'
import * as styles from './index.module.less'
import classnames from 'classnames'

interface SigningPlateProps {
  className?: string
  dataSource: any
}

const SigningPlate: React.FC<SigningPlateProps> = ({ className, dataSource }) => {
  const { left, right } = dataSource
  let { user } = useContext(providerContext)
  const [showSubscribeForm, setShowSubscribeForm] = useState<boolean>(false)
  const { show: showLoginDialogLeft } = useLoginDialog({
    onSuccess: () => {},
  })
  const { show: showLoginDialogRight } = useLoginDialog({
    onSuccess: () => {
      saTrackSolutionsOverviewLoginClick()
      getUserCertification()
    },
  })
  // 左侧订阅
  const handleClickLeftCard = () => {
    if (user) {
    } else {
      showLoginDialogLeft()
    }
  }
  // 右侧老客户专区
  const handleClickRightCard = () => {
    if (user) {
      getUserCertification()
    } else {
      showLoginDialogRight()
    }
  }
  // 老客户是否认证
  const getUserCertification = async () => {
    try {
      const {
        data: { certifiedStatus },
      } = await getUserCertificationNew()
      if (certifiedStatus === 1) {
        // 已认证
        window.location.href = 'https://school.sensorsdata.cn/space/customer'
      } else {
        // 未认证
        window.location.href = 'https://school.sensorsdata.cn/certificate?type=3'
      }
    } catch (err) {
      window.location.href = 'https://school.sensorsdata.cn/certificate?type=3'
      console.error(err)
    }
  }
  const isPcFlag = useMemo(() => {
    return isPc()
  }, [])
  return (
    <div
      className={classnames(
        styles.container,
        'lg:h-[400px] h-[188px] py-[22px] px-[10px] lg:px-0 lg:py-[60px] bg-[#F9FAFC]',
        className,
      )}
    >
      <div className="h-full lg:w-[1200px] lg:mx-auto flex justify-between">
        <div
          className="left-card-outside w-[50%] lg:w-[48%] lg:h-[280px] lg:shadow-[0_2px_10px_0_rgb(193,208,211,42%)] "
          // style={{ background: isPcFlag && 'linear-gradient(303deg, #DBFFF5 0%, #FFFFFF 100%)' }}
        >
          <div
            className="h-full   left-card"
            onClick={() => {
              setShowSubscribeForm(true)
            }}
          >
            <h4 className=" pt-[28px] lg:pt-[36px] text-[16px] lg:text-[18px] font-medium text-[#1f2d3d]">
              {left?.title}
            </h4>
            <p className="hidden lg:block w-[312px] text-[14px] text-[#475669] leading-[20px] mt-[14px]">
              {left?.desc}
            </p>
            <p className="mt-[22px] lg:mt-[54px]">
              {/* <Button btnType="link" size="small" className="text-left px-0 w-auto" href={left?.btn_link}> */}
              <Button btnType="link" size="small" className="text-left px-0 w-auto">
                {left?.btn_text}
              </Button>
              <span className="inline-block arrows-icon"></span>
            </p>
          </div>
        </div>
        <div
          className="right-card-outside w-[50%] lg:w-[48%] lg:shadow-[0_2px_10px_0_rgb(193,208,211,42%)]"
          // style={{ background: isPcFlag && 'linear-gradient(303deg, #DBFFF5 0%, #FFFFFF 100%)' }}
        >
          <div className="h-full right-card" onClick={handleClickRightCard}>
            <h4 className=" pt-[28px] lg:pt-[36px] text-[16px] lg:text-[18px] font-medium text-[#1f2d3d]">
              {right?.title}
            </h4>
            <p className="hidden lg:block  w-[312px] text-[14px] text-[#475669] leading-[20px] mt-[14px]">
              {right?.desc}
            </p>
            <p className="mt-[22px] lg:mt-[54px]">
              {/* <Button btnType="link" size="small" className="text-left px-0" href={right?.btn_link}> */}
              <Button btnType="link" size="small" className="text-left px-0">
                {right?.btn_text}
              </Button>
              <span className="inline-block arrows-icon"></span>
            </p>
          </div>
        </div>
      </div>
      {showSubscribeForm && <SubscribeForm handleClose={() => setShowSubscribeForm(false)} />}
    </div>
  )
}

export default SigningPlate
