/* eslint-disable */
import * as React from 'react'
import { fetchSms, fetchSmsVerify, fetchLogin, fetchCompanySearch } from 'api/user'
import { fetchSolutionsSubscribe } from 'api/index'
import { providerContext } from '../../Provider'
import * as styles from './index.module.less'
import PrivacyPolicy from '../../PrivacyPolicy'
import { isEmail, throttle } from 'utils/utils'
import { saTrack } from 'utils/statistics'
import classnames from 'classnames'

const { useReducer, useState, useRef, useEffect, useContext } = React

const reducer = (state, action) => ({ ...state, ...action })

const SubscribeForm = (props) => {
  const { handleClose } = props
  const { user } = useContext(providerContext)
  const inputList = user ? ['company', 'email'] : ['phone', 'code', 'name', 'company', 'email']

  const [state, setState] = useReducer(reducer, {}, () => {
    /* 
      以下为表单统一管理的数据（非统一管理的不要加在 return 的对象中）
      cont -- 输入或选择的内容
      errMsg -- 警告信息
      status -- input状态，0: 初始、1: 聚焦、2: 警告
    **/
    const stateOpts = inputList.reduce((target, key) => {
      target[key] = { cont: '', errMsg: '', status: 0 }
      return target
    }, {})
    return stateOpts
  })

  const phoneRef = useRef(null)
  const [isSelect, setSelect] = useState(true)
  const [submitResult, setSubmitResult] = useState('')
  const [codeVerifyRes, setCodeVerifyRes] = useState('')
  const [inputDisabled, setInputDisabled] = useState(false)
  const [showPrivacyPolicy, setPrivacyPolicy] = useState(false)
  const [codeBtn, setCodeBtn] = useState({ text: '获取验证码', disabled: false })
  const [searchCompanyList, setSearchCompanyList] = useState([])
  const [showCompanyList, setShowCompanyList] = useState(false)

  const { name, phone, code, company, email } = state

  useEffect(() => {
    // 禁止滑动穿透
    document.body.style.overflow = 'hidden'
    // 点击空白处失焦
    document.addEventListener('click', hideSelect)
    return () => {
      document.body.style.overflow = ''
      document.removeEventListener('click', hideSelect)
    }
  }, [])

  // 隐藏公司选择框
  const hideSelect = () => setShowCompanyList(false)

  // 是否展示输入项
  const showInput = (key) => inputList.includes(key)

  // 根据输入状态设置文本框颜色
  const statusRes = (type) => {
    if (state[type].status === 1) return 'focus'
    if (state[type].cont.trim() && !state[type].errMsg) return 'normal'
    return state[type].status ? (state[type].status === 1 ? 'focus' : 'error') : 'normal'
  }

  // 搜索匹配的公司
  const searchCompany = throttle((val) => {
    fetchCompanySearch({ search_company_keyword: val }).then((res) => {
      const {
        status,
        data: { data = [] },
      } = res
      setShowCompanyList(data?.length ? true : false)
      setSearchCompanyList(status === 200 ? data : [])
    })
  }, 100)

  const changeCompany = (val) => {
    state['company'].cont = val
    state['company'].errMsg = ''
    setState({ ...state })
    setShowCompanyList(false)
  }

  const handleChange = (e, key) => {
    state[key].cont = e.target.value
    state[key].errMsg = ''
    setState({ ...state })
    key === 'company' && searchCompany(e.target.value)
  }

  const handleFocus = (key) => {
    state[key].status = 1
    state[key].errMsg = ''
    setState({ ...state })
  }

  const handleBlur = (e, key) => {
    const val = e?.value || e?.target?.value
    const value = val?.trim()
    const errFun = (msg) => {
      state[key].status = 2
      state[key].errMsg = msg
      setState({ ...state })
    }

    // 未输入
    if (value === '' || value === undefined) return errFun('未填写')
    // 手机号码
    if (!user && key === 'phone' && !/^1\d{10}$/.test(value)) return errFun('格式错误')
    // 验证码
    if (!user && key === 'code' && phone.cont) return value.length === 4 ? codeVerify() : errFun('格式错误')
    // 邮箱
    if (key === 'email' && !isEmail(value)) return errFun('格式错误')

    state[key].status = 0
    setState({ ...state })
  }

  // 短信码验证
  const codeVerify = () => {
    fetchSmsVerify(phone.cont, code.cont).then((res) => {
      const { data } = res
      if (data.status === 200) {
        state['code'].status = 0
        state['code'].errMsg = ''
        setState({ ...state })
        setCodeVerifyRes(data.data.profile.user_id || '')
      } else {
        state['code'].status = 2
        state['code'].errMsg = data.desc || '验证码错误'
        setState({ ...state })
      }
    })
  }

  // 获取验证码
  const handleVerifyCode = () => {
    if (codeBtn.disabled) return

    const phoneCont = phone.cont?.trim()
    const phoneTest = /^1\d{10}$/.test(phoneCont)
    if (!phoneCont || !phoneTest) {
      handleBlur(phoneRef.current, 'phone')
    } else {
      fetchSms(phoneCont).then((res) => {
        if (res.status === 200) {
          setCountdown()
        } else {
          state['code'].errMsg = res.desc
          setState({ ...state })
        }
      })
    }
  }

  // 切换隐私条款
  const handleSelect = () => {
    let select = isSelect
    setSelect((select = !select))
  }

  // 获取验证码倒计时
  const setCountdown = () => {
    let countdown = 60
    const inter = setInterval(() => {
      if (countdown <= 0) {
        clearInterval(inter)
        setCodeBtn({ text: '获取验证码', disabled: false })
      } else {
        countdown--
        setCodeBtn({ text: `${countdown}s 后重试`, disabled: true })
      }
    }, 1000)
  }

  // 点击提交按钮验证所有
  const verifyAll = () => {
    const errorList = inputList.filter((type) => !state[type].cont.trim() || state[type].errMsg)
    if (errorList.length) {
      errorList.forEach((type) => {
        state[type].status = 2
        state[type].errMsg = state[type].errMsg || '未填写'
      })
      setState({ ...state })
      return false
    }
    return true
  }

  // toast 显隐
  const handleSubmitResult = (result) => {
    setSubmitResult(result)
    const timer = setTimeout(() => {
      setSubmitResult('')
      clearTimeout(timer)
    }, 2000)
  }

  const subscription = (params) => {
    fetchSolutionsSubscribe(params)
      .then((res) => {
        callback(res)
      })
      .catch(() => {
        setInputDisabled(false)
        handleSubmitResult('订阅失败')
      })
  }

  const login = (params) => {
    fetchLogin(params)
      .then((res) => {
        callbackLogin(res)
      })
      .catch(() => {
        setInputDisabled(false)
        handleSubmitResult('订阅失败')
      })
  }

  const callbackLogin = (result) => {
    const { data } = result
    setInputDisabled(false)
    if (data.status === 200) {
      handleSubmitResult('订阅成功')
      setTimeout(handleClose, 2020)
      // saTrack('notification_formsubmit')
    } else {
      handleSubmitResult(data?.desc || '订阅失败')
    }
  }
  const callback = (result) => {
    const { data } = result
    setInputDisabled(false)
    if (data) {
      handleSubmitResult('订阅成功')
      setTimeout(handleClose, 2020)
      // saTrack('notification_formsubmit')
    } else {
      handleSubmitResult('订阅失败')
    }
  }

  const handleSubmit = () => {
    const verifyRes = verifyAll()
    if (!verifyRes) return
    if (!isSelect) return

    setInputDisabled(true)

    if (user) return subscription({ group_name: company.cont, enterprise_email: email.cont })

    const params = {
      code: code.cont,
      phone: phone.cont,
      user_name: name.cont,
      group_name: company.cont,
      enterprise_email: email.cont,
      spare_channel: 'solutions_overview_subscription',
    }
    codeVerifyRes ? subscription(params) : login(params)
  }

  const renderSearchCompanyList = () => {
    if (!searchCompanyList?.length) return null
    return (
      <ul className={classnames('select-list', showCompanyList ? 'show' : 'hide')}>
        {searchCompanyList?.map((item, index) => (
          <li key={index} className="select-item" onClick={() => changeCompany(item.name)}>
            {item.name}
          </li>
        ))}
      </ul>
    )
  }

  return (
    <div className={classnames(styles.SubscribeForm, user ? styles.flex : styles.block)}>
      <div className="form-container">
        <header className="form-header">
          <h2 className="form-title">
            尊敬的用户，请您填写以下信息，
            <br /> 进行内容订阅
          </h2>
          <span className="close" onClick={handleClose}>
            <img src={require('../../../assets/svgs/icon-close-white.svg').default} alt="close" />
          </span>
        </header>
        <main className="form-main">
          {/* <p className="form-hint">注意：一定要填写正确的「公司邮箱」，这是我们判断您是老客户的依据</p> */}
          {showInput('phone') && (
            <div className="form-item">
              <h3 className="require">手机号码</h3>
              <input
                type="tel"
                ref={phoneRef}
                className={statusRes('phone')}
                maxLength="13"
                pattern="[0-9]"
                onChange={(e) => handleChange(e, 'phone')}
                onFocus={() => handleFocus('phone')}
                onBlur={(e) => handleBlur(e, 'phone')}
                placeholder="请输入您的电话号码"
                autoComplete="off"
              />
              {phone.errMsg && <p className="error-msg">{phone.errMsg}</p>}
            </div>
          )}
          {showInput('code') && (
            <div className="form-item">
              <h3 className="require">验证码</h3>
              <div className="code-wrap">
                <input
                  type="tel"
                  maxLength="4"
                  className={statusRes('code')}
                  onChange={(e) => handleChange(e, 'code')}
                  onFocus={() => handleFocus('code')}
                  onBlur={(e) => handleBlur(e, 'code')}
                  placeholder="请输入验证码"
                  autoComplete="off"
                />
                <button
                  disabled={codeBtn.disabled}
                  className={classnames('code-btn', { 'get-code': codeBtn.disabled })}
                  onClick={handleVerifyCode}
                >
                  {codeBtn.text}
                </button>
              </div>
              {code.errMsg && <p className="error-msg">{code.errMsg}</p>}
            </div>
          )}
          {showInput('name') && (
            <div className="form-item">
              <h3 className="require">姓名</h3>
              <input
                type="text"
                className={statusRes('name')}
                onChange={(e) => handleChange(e, 'name')}
                onFocus={() => handleFocus('name')}
                onBlur={(e) => handleBlur(e, 'name')}
                placeholder="请输入您的姓名"
                autoComplete="off"
              />
              {name.errMsg && <p className="error-msg">{name.errMsg}</p>}
            </div>
          )}
          <div className="form-item multi-select">
            <h3 className="require">公司名称</h3>
            <input
              type="text"
              value={company.cont}
              className={statusRes('company')}
              onChange={(e) => handleChange(e, 'company')}
              onFocus={() => handleFocus('company')}
              onBlur={(e) => handleBlur(e, 'company')}
              onClick={(e) => searchCompany(e.target.value)}
              placeholder="请输入您的公司名称"
              autoComplete="off"
            />
            {company.errMsg && <p className="error-msg">{company.errMsg}</p>}
            {renderSearchCompanyList()}
          </div>
          <div className="form-item">
            <h3 className="require">邮箱</h3>
            <input
              type="text"
              className={statusRes('email')}
              onChange={(e) => handleChange(e, 'email')}
              onFocus={() => handleFocus('email')}
              onBlur={(e) => handleBlur(e, 'email')}
              placeholder="请输入您的邮箱"
              autoComplete="off"
            />
            {email.errMsg && <p className="error-msg">{email.errMsg}</p>}
          </div>
          {inputDisabled && <div className="mask" />}
          <div className="privacy-policy">
            <p className="btns">
              <span className={classnames('checkbox', { selected: isSelect })} onClick={handleSelect} />
              <span className="text" onClick={handleSelect}>
                《隐私条款》
              </span>
              <span className="readme" onClick={() => setPrivacyPolicy(true)}>
                (点我阅读)
              </span>
            </p>
            {!isSelect && <p className="error-msg">请勾选《隐私条款》</p>}
          </div>
        </main>
        <section className="form-submit">
          <button className="btn" onClick={handleSubmit}>
            提交
          </button>
        </section>
        {submitResult && <div className="submit-toast">{submitResult}</div>}
      </div>
      <PrivacyPolicy isShow={showPrivacyPolicy} onClose={() => setPrivacyPolicy(false)} />
    </div>
  )
}

export default SubscribeForm
/* eslint-disable no-new */
