import React, { useMemo, useRef, useEffect, useState } from 'react'
import classnames from 'classnames'
import SwiperCore, { Navigation, Pagination, Thumbs, EffectFade } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { isPc } from 'utils/utils'

import * as styles from './index.module.less'
SwiperCore.use([Navigation, Pagination, Thumbs, EffectFade])

interface ExpertSwiperProps {
  autoPlay?:
    | boolean
    | {
        delay: number
      }
  className?: string
  lazyLoading?: boolean
  dataSource: {
    index: number
    name: string
    desc: string
    position: string
    tag: string
    person_img: string | { publicURL: string }
    person_img_mb?: string | { publicURL: string }
    photo?: string | { publicURL: string }
    photo_mb?: string | { publicURL: string }
  }[]
  active?: number
  handleChangeIndex?: (index: number) => void
}
/**
 *
 * @param className 类名
 * @param active 全局当前激活状态索引 例：0 或 1 ...
 * @param handleChangeIndex 修改激活状态索引值
 * @param dataSource 静态数据
 * @returns
 */

const ExpertSwiper: React.FC<ExpertSwiperProps> = ({
  className,
  dataSource,
  active = 0,
  handleChangeIndex,
  lazyLoading = false,
  autoPlay = false,
}) => {
  const myRightSwiper = useRef<SwiperCore>()
  const myLeftSwiper = useRef<SwiperCore>()
  const myLeftSwiperMb = useRef<SwiperCore>()
  const myRightSwiperMb = useRef<SwiperCore>()
  const [activeData, setActiveData] = useState(dataSource[0])
  const filterDataSource = useMemo(() => {
    return dataSource.filter((item) => item?.name)
  }, [dataSource])
  useMemo(() => {
    const activeIndexData = dataSource.filter((item, index) => {
      if (item?.index === active) {
        myLeftSwiperMb.current?.slideTo(index)

        return true
      }
    })
    filterDataSource.forEach((item, index) => {
      if (item.index === active) {
        myRightSwiper.current?.slideTo(index)
        myLeftSwiper.current?.slideTo(item.index)
        myRightSwiperMb.current?.slideTo(index + 1)
      }
    })
    !!activeIndexData.length && setActiveData(activeIndexData[0])
  }, [active])
  useEffect(() => {
    if (isPc()) {
      myRightSwiper.current = new SwiperCore('.my-right-swiper', {
        slidesPerView: 4,
        spaceBetween: 26,
        freeMode: true,
        autoplay: false,
        navigation: {
          nextEl: '.my-swiper-button-next',
          prevEl: '.my-swiper-button-prev',
          disabledClass: 'my-button-disabled',
        },
        watchSlidesProgress: true,
        watchSlidesVisibility: true,

        on: {
          init: (swiper) => {
            // 修复thumbs bug
            if (myLeftSwiper.current) {
              myLeftSwiper.current.thumbs.swiper = swiper
              myLeftSwiper.current.thumbs.init()
            }
            // 初始化 隐藏部分item
            dataSource.forEach((item, index) => {
              if (!item.name) {
                isPc() && swiper.slides[index].classList.add('hidden')
              }
            })
            swiper.appendSlide([
              '<div class="swiper-slide" />',
              '<div class="swiper-slide" />',
              '<div class="swiper-slide" />',
            ])
            swiper.update()
          },
          slideChange: (swiper) => {
            swiper.update()
          },
        },
      })
    } else {
      myRightSwiperMb.current = new SwiperCore('.my-right-mobile-swiper', {
        // initialSlide: active + 1,
        autoplay: false,
        slidesPerView: 3,
        spaceBetween: 12,
        freeMode: true,
        watchSlidesProgress: true,
        watchSlidesVisibility: true,
        allowTouchMove: false,
        simulateTouch: false,
        navigation: {
          nextEl: '.my-swiper-mb-button-next',
          prevEl: '.my-swiper-mb-button-prev',
          disabledClass: 'my-button-disabled',
        },
        on: {
          init: (swiper) => {
            // 初始化 隐藏部分item
            // dataSource.forEach((item, index) => {
            //   if (!item?.name) {
            //     !isPc() && swiper.slides[index].classList.add('hidden')
            //   }
            // })
            swiper.appendSlide([
              '<div class="swiper-slide" />',
              '<div class="swiper-slide" />',
              '<div class="swiper-slide" />',
            ])
            swiper.update()
          },
          slideChange: (swiper) => {
            // 阻止swiper滑动到第一个

            if (swiper.activeIndex === 0) {
              swiper.slideTo(swiper.previousIndex)
            } else if (swiper.activeIndex === 1) {
              swiper.navigation.prevEl.classList.add('my-button-disabled')
            } else {
              swiper.navigation.prevEl.classList.remove('my-button-disabled')
            }
            setActiveData(dataSource[filterDataSource[swiper.activeIndex - 1]?.index])
            myLeftSwiperMb.current?.slideTo(filterDataSource[swiper.activeIndex - 1]?.index)
            handleChangeIndex?.(filterDataSource[swiper.activeIndex - 1]?.index)
            swiper.update()
          },
        },
      })
      myLeftSwiperMb.current = new SwiperCore('.my-left-mobile-swiper', {
        autoplay: false,

        effect: 'fade',
        simulateTouch: false,
        allowTouchMove: false,
        // thumbs: { swiper: myRightSwiperMb.current },
        on: {
          init: (swiper) => {
            // 初始化 隐藏部分item
            // dataSource.forEach((item, index) => {
            //   if (!item?.name) {
            //     !isPc() && swiper.slides[index].classList.add('hidden')
            //   }
            // })
            // swiper.update()
          },
        },
      })
    }
  }, [])

  return (
    <div className={classnames(styles.container, 'lg:h-[564px] lg:pt-[100px]', className)}>
      {/* pc */}
      <div className="w-[1200px] h-full mx-auto lg:flex hidden ">
        <div className="content-left h-full !w-[380px] ">
          <Swiper
            onSwiper={(swiper) => {
              myLeftSwiper.current = swiper
            }}
            onSlideChange={(swiper) => {
              isPc() && setActiveData(dataSource[swiper.activeIndex])
              // handleChangeIndex?.(swiper.activeIndex)
            }}
            autoplay={autoPlay}
            simulateTouch={false}
            thumbs={{
              swiper: myRightSwiper.current,
            }}
            effect="fade"
            className="my-left-swiper w-full h-full"
          >
            {dataSource?.map((item: any, index) => {
              return (
                <SwiperSlide key={item?.name + index}>
                  <div className="flex justify-end w-full h-full">
                    <img
                      className={classnames(' w-full', { 'solution-overview-lazy-img': lazyLoading })}
                      src={lazyLoading ? '' : item?.person_img?.publicURL ?? item?.person_img}
                      data-src={item?.person_img?.publicURL ?? item?.person_img}
                      alt={item?.name}
                    />
                  </div>
                </SwiperSlide>
              )
            })}
          </Swiper>
        </div>
        <div className="content-right  pl-[20px] pt-[62px] flex-1 overflow-hidden">
          <p>
            <span className="text-[18px] font-medium leading-[24px] text-[#1F2D3D]">{activeData?.name}</span>
            <span className="inline-block ml-[18px] py-[2px] px-[8px] text-[12px] text-[#04CB94] bg-[rgba(4,203,148,0.1)] leading-[18px] rounded-[4px]">
              {activeData?.tag}
            </span>
          </p>
          <p className="text-[16px] text-[#1F2D3D] leading-[22px] mt-[8px]">{activeData?.position}</p>
          <p className="text-[14px] w-[90%] h-[100px] leading-[24px] text-[#475669] mt-[8px]" key={activeData?.desc}>
            {activeData?.desc}
          </p>
          <div className="person-swiper-small w-[90%] px-[50px] mt-[45px] relative">
            <div className="my-right-swiper swiper-container">
              <div className="swiper-wrapper">
                {dataSource?.map((item: any, index) => {
                  return (
                    <div className="swiper-slide" key={index}>
                      <div className="h-auto w-auto text-center">
                        <img
                          className={classnames('w-full h-full cursor-pointer', {
                            'solution-overview-lazy-img': lazyLoading,
                          })}
                          src={lazyLoading ? '' : item?.photo?.publicURL ?? item?.photo}
                          data-src={item?.photo?.publicURL ?? item?.photo}
                          alt={item?.name}
                        />
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
            <div className={classnames('my-swiper-button-next')} />
            <div className={classnames('my-swiper-button-prev')} />
          </div>
        </div>
      </div>
      {/* mb */}
      <div className="w-full h-[30rem] lg:hidden section-swiper-mb pt-[3rem] flex relative">
        {/* mb-leftContent */}
        <div className="h-full ml-[2rem] w-[16rem] ">
          <div className={classnames('my-swiper-mb-button-next')} />
          <div className={classnames('my-swiper-mb-button-prev')} />
          <div className="my-left-mobile-swiper   swiper-container">
            <div className="swiper-wrapper">
              {dataSource?.map((item: any, index) => {
                return (
                  <div className="swiper-slide" key={index + 'mb'}>
                    <div className="h-full w-[15.8rem] flex justify-end flex-col">
                      <img
                        className={classnames('w-full', { 'solution-overview-lazy-img': lazyLoading })}
                        src={lazyLoading ? '' : item?.person_img_mb?.publicURL ?? item?.person_img_mb}
                        data-src={item?.person_img_mb?.publicURL ?? item?.person_img_mb}
                        alt={item?.name}
                      />
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        {/* mb-rightContent */}
        <div className="right-content overflow-hidden flex-1">
          <div className="pt-[66px]">
            <p className="w-full">
              <span className="text-[16px] font-medium leading-[22px] text-[#1F2D3D]">{activeData?.name}</span>
              <span className="inline-block ml-[18px] py-[2px] px-[8px] text-[12px] text-[#04CB94] bg-[rgba(4,203,148,0.1)] leading-[18px] rounded-[4px]">
                {activeData?.tag}
              </span>
            </p>
            <p className="w-[14rem] min-h-[3.7rem] text-[12px] text-[#1F2D3D] leading-[18px] mt-[8px] flex flex-col">
              <span> {activeData?.position}</span>
              <span className="flex-1"></span>
            </p>
          </div>
          <div className="w-full pl-[12px] pr-[20px] mt-[34px] h-[5rem]">
            <div className="my-right-mobile-swiper swiper-container">
              <div className="swiper-wrapper">
                {dataSource?.map((item: any, index) => {
                  if (!!item.name) {
                    return (
                      <SwiperSlide key={item?.name + index + 'mb-right'}>
                        <div>
                          <img
                            className={classnames('h-full w-full', { 'solution-overview-lazy-img': lazyLoading })}
                            src={lazyLoading ? '' : item?.photo_mb?.publicURL ?? item?.photo_mb}
                            data-src={item?.photo_mb?.publicURL ?? item?.photo_mb}
                            alt={item?.name}
                          />
                        </div>
                      </SwiperSlide>
                    )
                  } else {
                    return <></>
                  }
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ExpertSwiper
