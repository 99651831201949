import React from 'react'
import Button from 'components/Base/Button'
import { isPc } from 'utils/utils'
import classnames from 'classnames'
import * as styles from './index.module.less'

interface IndustryCardProps {
  className?: string
  dataSource: any
}

const IndustryCard: React.FC<IndustryCardProps> = ({ className, dataSource }) => {
  return (
    <a href={dataSource?.btn_link} target="_blank" className={classnames(styles.container, 'block', className)}>
      <div className="card-top-time hidden lg:block text-center text-[14px] leading-[30px] text-[#475669] ">
        {dataSource?.time}
      </div>
      <div className="mt-[10px] h-[28px] hidden lg:block lg:relative">
        <div className="card-play-icon w-[28px] h-[28px] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] z-[2]"></div>
        <div className="absolute left-0 right-0 h-[2px] bg-[#D5F3EB] top-[50%] translate-y-[-50%]"></div>
      </div>
      <div className="w-[274px] mx-auto mt-[20px] ">
        <p>
          <img className="rounded-t-[4px] " src={dataSource?.card_img?.publicURL || dataSource?.card_img} alt="" />
        </p>
        <div className="industry-card !rounded-b-[4px] py-[16px] px-[14px]">
          <h4 className="industry-card-title text-[18px] font-medium text-[#1f2d3d] leading-[24px]">
            {dataSource?.title}
          </h4>
          <p className="mt-[20px] flex justify-between items-center">
            <span className="text-[12px] leading-[20px] text-[#475669]">{dataSource?.time_detail}</span>
            <span>
              <Button btnType={isPc() ? 'primary' : 'text'} size="small" href={dataSource?.btn_link} target="_blank">
                {dataSource?.btn_text}
              </Button>
              <span className="inline-block lg:hidden arrows-icon"></span>
            </span>
          </p>
        </div>
      </div>
    </a>
  )
}

export default IndustryCard
