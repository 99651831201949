import React, { useState, useRef, useEffect, useMemo } from 'react'
import { graphql, useStaticQuery } from 'gatsby'

// components
import Layout from 'layouts/zh'
import Seo from 'components/seo'
import VideoPlayer from 'components/VideoPlayer'
import Banner from 'components/SolutionComponents/Banner'
import Tag from 'components/SolutionComponents/Tag'
import Section from 'components/SolutionComponents/Section'
import Solution from 'components/SolutionComponents/Solution'
import CustomerVoice from 'components/SolutionComponents/CustomerVoice'
import ExpertSwiper from 'components/SolutionComponents/ExpertSwiper'
import CaseShare from 'components/SolutionComponents/CaseShare'
import IndustryCard from 'components/SolutionComponents/IndustryCard'
import SigningPlate from 'components/SolutionComponents/SigningPlate'
import IndexDismantling from 'components/SolutionComponents/IndexDismantling'
import ScanCodeConsultButton from 'components/ScanCodeConsultButton'
import PageBottomCard from 'components/PageBottomCardNew'
import Button from 'components/Base/Button'

import { searchTarget } from 'api/doc'
import { getHotTagList } from 'api/index'
import 'swiper/swiper-bundle.css'
import classNames from 'classnames'

interface OverviewProps {}

const Overview: React.FC<OverviewProps> = (props) => {
  return (
    <Layout {...props}>
      <OverviewState />
    </Layout>
  )
}

export const OverviewState: React.FC = (props) => {
  const { overviewYaml } = useStaticQuery(graphql`
    query {
      overviewYaml {
        title
        desc
        keywords
        saTitle
        banner_icon {
          publicURL
        }
        banner {
          title
          banner_video_img {
            publicURL
          }
          desc
          btn_text
          btn_href
          banner_video
        }
        public_content {
          tag {
            index
            name
          }
          expert_person {
            index
            person_img {
              publicURL
            }
            person_img_mb {
              publicURL
            }
            photo {
              publicURL
            }
            photo_mb {
              publicURL
            }
            name
            position
            desc
            tag
          }
        }
        dataDetail {
          name
          index
          data {
            solution {
              title
              mask_title
              desc
              card_title
              card_desc
              card_btn_text
              card_btn_link
              solution_video_img {
                publicURL
              }
              video_url
            }
            industry_experts {
              title
              mask_title
              desc
            }
            customer_voice {
              title
              mask_title
              content_title
              content_desc
              content_person
              content_file_img {
                publicURL
              }
              content_file_link
              content_video_img {
                publicURL
              }
              content_video_link
            }
            case_share {
              title
              mask_title
              desc
              section_one {
                title
                desc
                btn_text
                btn_link
                content_img {
                  publicURL
                }
              }
              btn_more_link
              btn_more_text
              section_two {
                title
                desc
                tag
                btn_text
                btn_link
                right_img {
                  publicURL
                }
              }
            }
            industry_seminar {
              title
              mask_title
              desc
              dataSource {
                time
                card_img {
                  publicURL
                }
                title
                time_detail
                btn_text
                btn_link
              }
              card_data {
                left {
                  title
                  desc
                  btn_text
                  btn_link
                }
                right {
                  title
                  desc
                  btn_text
                  btn_link
                }
              }
            }
            index_dismantling {
              title
              mask_title
            }
          }
        }
      }
    }
  `)

  const [hotTags, setHotTags] = useState<
    Array<{
      tagName: string
      tagId: string
    }>
  >([])
  const [overviewIndex, setOverviewIndex] = useState(0)
  const curVideo = useRef<any>(null)
  const observer = useRef<any>(null)
  const { title, desc, keywords, saTitle, banner, banner_icon, public_content, dataDetail } = overviewYaml

  // 全局页面索引改变
  const handleChangeIndex = (index) => {
    setOverviewIndex(index)
  }
  const data = useMemo(() => {
    return dataDetail[overviewIndex]?.data
  }, [overviewIndex])
  const customerVoiceFlag = useMemo(() => {
    return !!data?.customer_voice?.content_title
  }, [data])

  // 获取热门指标
  useEffect(() => {
    getHotTagList()
      .then(({ data }) => {
        setHotTags(
          data.map((obj) => {
            return {
              tagName: obj.targetName,
              tagId: obj.targetId,
            }
          }),
        )
      })
      .catch((err) => {
        console.error(err)
      })
  }, [])
  // 回调
  const callback = (entries) => {
    entries.forEach((entry) => {
      // 出现在可视区域
      if (entry.isIntersecting) {
        const image = entry.target
        const data_src = image.getAttribute('data-src')
        const src = image.getAttribute('src')
        if (!src) {
          image.setAttribute('src', data_src)
          observer.current.unobserve(image)
        } else {
          observer.current.unobserve(image)
        }
      }
    })
  }
  // 图片懒加载
  useEffect(() => {
    const images = document.querySelectorAll('.solution-overview-lazy-img')
    observer.current = new IntersectionObserver(callback)
    images.forEach((image) => {
      observer.current.observe(image)
    })
  }, [overviewIndex])
  const hidden = useMemo(() => {
    return !public_content?.expert_person[overviewIndex]?.name
  }, [overviewIndex])
  return (
    <>
      <Seo title={title} description={desc} keywords={keywords} saTitle={saTitle} />
      <main className="lg:pt-[0] pt-[4rem]">
        <Banner
          active={overviewIndex}
          handleChangeIndex={handleChangeIndex}
          bannerData={banner}
          bannerIcon={banner_icon?.publicURL || banner_icon}
          curVideo={curVideo}
        />
        {/* 行业解决方案 */}
        <Section
          title={data?.solution?.title}
          maskTitle={data?.solution?.mask_title}
          desc={data?.solution?.desc}
          className="pt-[94px] lg:pt-0"
        >
          <Tag
            active={overviewIndex}
            handleChangeIndex={handleChangeIndex}
            className="lg:mt-[74px]"
            tagData={public_content?.tag}
          />
          <Solution className="mt-[60px] " active={overviewIndex} dataSource={data?.solution} curVideo={curVideo} />
        </Section>
        {/* 行业专家 */}
        {
          <Section
            className={classNames({
              'h-0 overflow-hidden invisible': hidden,
            })}
            title={data?.industry_experts?.title}
            maskTitle={data?.industry_experts?.mask_title}
            desc={data?.industry_experts?.desc}
          >
            <ExpertSwiper
              lazyLoading={true}
              active={overviewIndex}
              // handleChangeIndex={handleChangeIndex}
              dataSource={public_content?.expert_person}
            />
            <div className={classNames('pt-[30px] text-center  lg:hidden', { 'bg-[#f9fafc]': !customerVoiceFlag })}>
              <Button btnType="primary" href="https://www.sensorsdata.cn/group/">
                了解更多详情
              </Button>
            </div>
          </Section>
        }
        {/* 客户声音 */}
        {customerVoiceFlag && (
          <Section
            className="pb-[50px] lg:pb-0"
            title={data?.customer_voice?.title}
            maskTitle={data?.customer_voice?.mask_title}
          >
            <CustomerVoice active={overviewIndex} dataSource={data?.customer_voice} curVideo={curVideo} />
          </Section>
        )}
        {!customerVoiceFlag && <div className="hidden lg:block h-[100px] lg:bg-[#f9fafc]" />}
        {/* 行业洞察与案例分享 */}
        <Section
          className="bg-[#f9fafc] !pt-[26px] lg:pt-[50px] lg:!mt-auto"
          title={data?.case_share?.title}
          maskTitle={data?.case_share?.mask_title}
          desc={data?.case_share?.desc}
        >
          <CaseShare dataSource={data?.case_share} />
        </Section>
        {/* 行业研讨会 */}
        <Section
          title={data?.industry_seminar?.title}
          maskTitle={data?.industry_seminar?.mask_title}
          desc={data?.industry_seminar?.desc}
        >
          <div className="lg:mt-[60px] mb-[60px] lg:mb-[80px] flex flex-nowrap overflow-x-scroll lg:w-[1200px] lg:mx-auto">
            {data?.industry_seminar?.dataSource?.map((el, index) => {
              return (
                <IndustryCard dataSource={el} className="flex-1 pl-[20px] lg:pl-0 pb-[10px]" key={index + el?.title} />
              )
            })}
          </div>
          <SigningPlate className=" lg:block" dataSource={data?.industry_seminar?.card_data} />
        </Section>
        {/* 业务指标拆解工具 */}
        <Section
          title={data?.index_dismantling?.title}
          maskTitle={data?.index_dismantling?.mask_title}
          desc={data?.index_dismantling?.desc}
        >
          <IndexDismantling
            className="lg:w-[1062px] mx-[20px] px-[12px] pt-[30px] lg:pt-0 lg:px-0 mt-[30px] lg:h-[452px] lg:mx-auto lg:mt-[52px] lg:mb-[100px] "
            tags={hotTags}
            onSearch={(key) => {
              return new Promise((resolve, reject) => {
                setTimeout(() => {
                  if (key.length > 0) {
                    searchTarget(key, 1, 100)
                      .then((res) => {
                        const { data } = res.data
                        resolve(
                          data.map((obj) => {
                            return {
                              name: obj.name,
                              key: obj.objId,
                              cate: obj.tagName,
                            }
                          }),
                        )
                      })
                      .catch((err) => reject(err))
                  } else {
                    resolve([])
                  }
                }, 0)
              })
            }}
          />

          <div className="mt-[30px] pb-[60px] block lg:hidden text-center">
            <ScanCodeConsultButton
              buttonText="咨询专属顾问"
              imgBottomText="微信扫码 即刻咨询"
              size="small"
              qrcode="https://ow-file.sensorsdata.cn/www/solutions/overview/dismantling_icon_new.png"
            />
          </div>
        </Section>
        <PageBottomCard
          title="开启大数据分析与营销科技之旅！"
          desc="立即注册，和神策数据专家一起探讨数字化！"
          leftButtonText="体验 Demo"
          leftButtonHref="/demo/demo.html"
          rightButtonText="预约演示"
          rightButtonHref="/form/parade.html"
        />
        <VideoPlayer
          videoOpt={{
            sources: '',
          }}
          fuRef={curVideo}
        />
      </main>
    </>
  )
}

export default Overview
