import React, { useContext, useMemo } from 'react'
import { providerContext } from 'components/Provider'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination } from 'swiper'
import useLoginDialog from 'hooks/useLoginDialog'
import { saTrackSolutionsOverviewLoginClick } from 'utils/statistics'
import * as styles from './index.module.less'
import classnames from 'classnames'

SwiperCore.use([Pagination])
interface CustomerVoiceProps {
  className?: string
  dataSource: {
    content_title: string
    content_desc: string
    content_file_img: any
    content_file_link: string
    content_video_img: any
    content_video_link: string
    content_person: string
  }
  curVideo?: any
  active?: number
}

const CustomerVoice: React.FC<CustomerVoiceProps> = ({ className, dataSource, curVideo, active }) => {
  let { user } = useContext(providerContext)
  // 登录弹窗hook
  const { show: showLoginDialog } = useLoginDialog({
    // 登录成功后回调
    onSuccess: () => {
      saTrackSolutionsOverviewLoginClick()
      window.open(dataSource?.content_file_link)
      window.location.reload()
    },
  })
  const handleClickFile = () => {
    if (user) {
      window.open(dataSource?.content_file_link)
    } else {
      showLoginDialog()
    }
  }

  const handleClickVideoWarp = () => {
    // 动态调整video资源地址
    if (curVideo.current) {
      curVideo.current.changeSource({ src: dataSource?.content_video_link })
    }
    setTimeout(() => {
      curVideo.current.playVideo()
    }, 200)
  }
  useMemo(() => {}, [active, curVideo.current])

  return (
    <div
      className={classnames(
        styles.container,
        'lg:pt-[60px] lg:pb-[200px] lg:w-[1100px] lg:relative mx-auto',
        className,
      )}
    >
      {/* pc */}
      <div className="hidden lg:block">
        <div className="w-full h-[350px] px-[40px] py-[40px] voice-content relative">
          <div className="absolute w-[94px] h-[80px] top-[40px] left-[40px] content-mask-bg"></div>
          <h3 className="mt-[18px] text-[18px] font-medium leading-[24px] text-[#1f2d3d]">
            {dataSource?.content_title}
          </h3>
          <p className="mt-[20px] leading-[28px] text-[14px] text-[#475669]">{dataSource?.content_desc}</p>
          <p className="leading-[28px] text-[14px] text-[#475669] text-right">
            <p className="  content-person">{dataSource?.content_person}</p>
          </p>
        </div>
        <div className="absolute w-[73%] left-[50%] translate-x-[-50%] top-[284px] h-[214px]  flex justify-between">
          <div className="w-[380px] relative rounded-[4px]" onClick={handleClickFile}>
            <span className="voice_mask absolute top-0 left-0 bottom-0 right-0 bg-[rgba(0,0,0,0.3)] cursor-pointer">
              <img
                className="absolute w-[44px] h-[44px] left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]"
                src="https://ow-file.sensorsdata.cn/www/solutions/overview/download_file_icon.png"
                alt=""
              />
            </span>
            <img
              className="w-full h-full"
              src={dataSource?.content_file_img?.publicURL || dataSource?.content_file_img}
              alt=""
            />
          </div>
          <div className="w-[380px] relative rounded-[4px]" onClick={handleClickVideoWarp}>
            <span className="voice_mask absolute top-0 left-0 bottom-0 right-0 bg-[rgba(0,0,0,0.3)] cursor-pointer">
              <img
                className="absolute w-[44px] h-[44px] left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]"
                src="https://ow-file.sensorsdata.cn/www/solutions/overview/play_video_icon.png"
                alt=""
              />
            </span>
            <img
              className="w-full h-full"
              src={dataSource?.content_video_img?.publicURL || dataSource?.content_video_img}
              alt=""
            />
          </div>
        </div>
      </div>
      {/* mb */}
      <div className="lg:hidden mt-[20px] px-[10px] ">
        <div className="w-full px-[30px] py-[40px]  voice-content-mb relative">
          {/* mask icon */}
          <div className="absolute w-[48px] h-[40px] top-[40px] left-[24px] content-mask-bg"></div>
          <h3 className="mt-[12px]  text-[16px] leading-[22px] font-medium text-[#1f2d3d]">
            {dataSource?.content_title}
          </h3>
          <p className=" mt-[12px] text-[14px] leading-[24px] text-[#475669]">{dataSource?.content_desc}</p>
          <p className=" text-[14px] leading-[24px] text-[#475669] text-right">
            <span className="content-person">{dataSource?.content_person}</span>
          </p>
          <div className="mt-[20px]  ">
            <Swiper autoplay={true} pagination>
              <SwiperSlide className="pb-[30px]">
                <div className=" relative" onClick={handleClickFile}>
                  <span className="voice_mask absolute top-0 left-0 bottom-0 right-0 bg-[rgba(0,0,0,0.3)] cursor-pointer">
                    <img
                      className="absolute w-[44px] h-[44px] left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]"
                      src="https://ow-file.sensorsdata.cn/www/solutions/overview/download_file_icon.png"
                      alt=""
                    />
                  </span>
                  <img
                    className="w-full h-[172px]"
                    src={dataSource?.content_file_img?.publicURL || dataSource?.content_file_img}
                    alt=""
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className="pb-[30px]">
                <div className=" relative" onClick={handleClickVideoWarp}>
                  <span className="voice_mask absolute top-0 left-0 bottom-0 right-0 bg-[rgba(0,0,0,0.3)] cursor-pointer">
                    <img
                      className="absolute w-[44px] h-[44px] left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]"
                      src="https://ow-file.sensorsdata.cn/www/solutions/overview/play_video_icon.png"
                      alt=""
                    />
                  </span>
                  <img
                    className="w-full h-[172px]"
                    src={dataSource?.content_video_img?.publicURL || dataSource?.content_video_img}
                    alt=""
                  />
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CustomerVoice
