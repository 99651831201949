import React, { useEffect, useMemo, useState, useRef } from 'react'
import ScanCodeConsultButton from 'components/ScanCodeConsultButton'
import Button from 'components/Base/Button'

import { getSolutionsVideoView } from 'api/index'

import classnames from 'classnames'
import * as styles from './index.module.less'

interface SolutionProps {
  className?: string
  dataSource: any
  curVideo: any
  active?: number
}

const initNumber = [6412, 10055, 4167, 19289, 3745, 19587, 8690, 4685, 9768, 6633, 8570, 12822, 3946]

const Solution: React.FC<SolutionProps> = ({ className, dataSource, curVideo, active = 0 }) => {
  const [videoView, setVideoView] = useState()
  // 播放数量
  const handleSolutionsVideoView = async () => {
    try {
      const { data } = await getSolutionsVideoView(active)

      setVideoView(data + initNumber[active])
    } catch (err) {
      console.error(err)
    }
  }
  useEffect(() => {
    handleSolutionsVideoView()
  }, [active])
  const handleClickVideoWarp = () => {
    if (curVideo.current) {
      curVideo.current.changeSource({ src: dataSource?.video_url })
    }
    setTimeout(() => {
      curVideo.current.playVideo()
    }, 200)
  }
  // useMemo(() => {
  //   // 动态调整video资源地址
  //   if (curVideo.current) {
  //     curVideo.current.changeSource({ src: dataSource?.video_url })
  //   }
  // }, [active, curVideo.current])
  return (
    <>
      {/* pc */}
      <div className={classnames(styles.container, 'hidden lg:block w-[1200px] mx-auto relative', className)}>
        <div className="h-[490px] w-[834px]">
          <img src="https://ow-file.sensorsdata.cn/www/solutions/overview/overview_solution_bg.png" alt="" />
        </div>
        <div className="absolute h-[368px] left-[40px] right-0 top-[50%] translate-y-[-50%] flex solution-content">
          <div className="text-content w-[514px]  bg-[#fff]   h-full px-[48px] py-[48px] relative">
            <div>
              <span className="text-[18px] font-medium leading-[24px] text-[#1F2D3D]">{dataSource?.card_title}</span>
              <span className="inline-block ml-[18px] py-[2px] px-[8px] text-[12px] text-[#04CB94] bg-[rgba(4,203,148,0.1)] leading-[18px] rounded-[4px]">
                价值解读与场景实践
              </span>
            </div>
            <p className="text-[14px] text-[#475669] leading-[24px] mt-[6px]">{dataSource?.card_desc}</p>
            <p className=" mt-[30px] ">
              <span className="solution-eyes mr-[10px]" />
              <span className="text-[12px] text-[#475669]">视频观看量 {videoView}</span>
            </p>
            <p className="absolute left-[48px] bottom-[48px]">
              <ScanCodeConsultButton
                buttonText="咨询方案详情"
                imgBottomText="微信扫码 即刻咨询"
                qrcode="https://ow-file.sensorsdata.cn/www/solutions/overview/solution_detail_icon.png"
              />
            </p>
          </div>
          <button className="flex-1 relative" id="solutions_overview_video" onClick={handleClickVideoWarp}>
            <span className="absolute solution-video-mask cursor-pointer top-0 left-0 right-0 bottom-0 bg-[rgba(0,0,0,0.3)]"></span>
            <img
              src={dataSource?.solution_video_img?.publicURL || dataSource?.solution_video_img}
              className="w-full h-full "
              alt={dataSource?.card_title}
            />
            <span className="hidden">{active}</span>
          </button>
        </div>
      </div>
      {/* mb */}
      <div className={classnames(styles.container, ' block lg:hidden mt-[34px] mx-[20px]')}>
        <div className="solution-card-mb">
          <button className="relative" id="solutions_overview_video" onClick={handleClickVideoWarp}>
            <span className="absolute solution-video-mask cursor-pointer top-0 left-0 right-0 bottom-0 bg-[rgba(0,0,0,0.3)]"></span>
            <img
              src={dataSource?.solution_video_img?.publicURL || dataSource?.solution_video_img}
              className="w-full h-full "
              alt={dataSource?.card_title}
            />
            <span className="hidden">{active}</span>
          </button>
          <div className="py-[20px] px-[5%]">
            <p>
              <span className="inline-block  py-[2px] px-[8px] text-[12px] text-[#04CB94] bg-[rgba(4,203,148,0.1)] leading-[18px] rounded-[4px]">
                价值解读与场景实践
              </span>
            </p>
            <p className="mt-[10px] text-[16px] font-medium text-[#1f2d3d] leading-[22px]">{dataSource?.card_title}</p>
            <p className="mt-[4px] text-[14px] text-[#475669] leading-[24px]">{dataSource?.card_desc}</p>
            <p className="mt-[30px]">
              <span className="solution-eyes mr-[10px]" />
              <span className="text-[12px] text-[#475669]">视频观看量 {videoView}</span>
            </p>
          </div>
        </div>
        <p className="mt-[30px] text-center">
          <Button href={dataSource?.card_btn_link} btnType="primary" target="_blank">
            {dataSource?.card_btn_text}
          </Button>
        </p>
      </div>
    </>
  )
}

export default Solution
